import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../services/api";

import Loader from "../../components/Loader";

const List = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { ok, data } = await api.post("/company/search", { levee: true });
        if (!ok) throw new Error("Error while fetching companies");

        setCompanies(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="p-8">
      <h2 className="text-2xl font-semibold mb-4">Services de Levée de fonds</h2>

      <section className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center px-4 mb-4">
          <h3 className="flex-1 text-sm font-semibold">Entreprise</h3>
          <h3 className="w-[20%] text-sm font-semibold">Créer par</h3>
          <h3 className="w-[20%] text-sm font-semibold">Date de création</h3>
          <h3 className="w-[20%] text-sm font-semibold">Paiement</h3>
        </div>
        <div className="space-y-2">
          {loading ? (
            <Loader />
          ) : (
            companies.map((e, index) => (
              <Link
                to={`/levee-service/${e._id}`}
                key={index}
                className="border border-secondary hover:border-black transition-all rounded-lg  flex justify-between items-center p-4">
                <div className="flex-1">
                  <div>{e.company_name}</div>
                </div>
                <div className="flex-1">{e.annonce_title}</div>
                <div className="w-[20%]">
                  {e.user_first_name} {e.user_last_name}
                </div>
                <div className="w-[20%]">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
                <div className="w-[20%]">
                  {e.levee_has_paid ? (
                    <span className="text-green-500 border border-green-500 bg-green-100 rounded px-2 py-1">Validée</span>
                  ) : (
                    <span className="text-orange-500 border border-orange-500 bg-orange-100 rounded px-2 py-1">Non payé</span>
                  )}
                </div>
              </Link>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default List;
